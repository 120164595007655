<template>
	<div
		class="edit-style-container"
		:class="{ 'edit-style-container--active': isActive }"
	>
		<div class="edited edit-style-container__content">
			{{ $t('common.edited') }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped lang="scss">
@import '@/components/builder-drawers/drawers/partials/stylesDrawer/UserStyles.scss';

.edited {
	padding-bottom: 8px;
	text-align: left;
}
</style>
